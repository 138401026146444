<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <!-- 사고종류 -->
          <c-multi-select
            codeGroupCd="IIM_NEAR_KIND_CD"
            :editable="editable"
            itemText="codeName"
            itemValue="code"
            maxValues="3"
            name="accidentKindCdList"
            label="아차사고종류"
            v-model="searchParam.accidentKindCdList"
          ></c-multi-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <!-- 아차사고등급 -->
          <c-select
            type="search"
            codeGroupCd="IIM_GRADE_CD"
            itemText="codeName"
            itemValue="code"
            name="accidentGradeCd"
            label="아차사고등급"
            v-model="searchParam.accidentGradeCd"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <!-- 진행상태 -->
          <c-select
            type="search"
            codeGroupCd="IIM_STATUS_NEAR_CD"
            itemText="codeName"
            itemValue="code"
            name="accidentStatusCd"
            label="진행상태"
            v-model="searchParam.accidentStatusCd"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <!-- 발생일자 -->
          <c-datepicker
            :range="true"
            name="period"
            label="발생일자"
            v-model="period"
          ></c-datepicker>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="아차사고 목록"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      rowKey="iimAccidentId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn label="등록" v-if="editable" icon="add" @btnClicked="onItemClick" />
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "near-accident",
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "공사현장",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "accidentNo",
            field: "accidentNo",
            label: "아차사고번호",
            style: 'width:120px',
            align: "center",
            sortable: true,
          },
          {
            name: "accidentName",
            field: "accidentName",
            label: "아차사고명",
            style: 'width:200px',
            align: "left",
            sortable: true,
            type: "link",
          },
          {
            name: "processName",
            field: "processName",
            label: "공종",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "accidentStatusName",
            field: "accidentStatusName",
            style: 'width:100px',
            label: "아차사고처리단계",
            align: "center",
            sortable: true,
          },
          {
            name: "accidentKindCd",
            field: "accidentKindCd",
            style: 'width:120px',
            label: "아차사고종류",
            align: "left",
            sortable: true,
          },
          {
            name: "accidentGradeName",
            field: "accidentGradeName",
            label: "아차사고등급",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "occurrenceLocation",
            field: "occurrenceLocation",
            label: "발생가능장소",
            style: 'width:100px',
            align: "left",
            sortable: true,
          },
          {
            name: "occurrenceDate",
            field: "occurrenceDate",
            style: 'width:100px',
            label: "발생가능일시",
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        startYmd: '',
        endYmd: '',
        accidentGradeCd: null,
        occurrenceDeptCd: '',
        accidentKindCdList: [],
        accidentStatusCd: null,
        processCd: '',
      },
      accidentKindCdList: [],
      period: [],
      editable: true,
      searchUrl: "",
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        isFull: true,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.iim.accident.near.list.url;
      this.deleteUrl = transactionConfig.sop.iim.accident.near.delete.url;
      // url setting
      // list setting
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    onItemClick() {
      this.linkClick(null);
    },
    linkClick(row, col, index) {
      this.popupOptions.title = "아차사고 상세"; // 아차사고 상세
      this.popupOptions.param = {
        iimAccidentId: row ? row.iimAccidentId : '',
      };
      this.popupOptions.target = () =>
        import(`${"./nearAccidentDetail.vue"}`);
      this.popupOptions.width = "80%"
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (type === 'REMOVE') {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
      }
      this.getList();
    },
  },
};
</script>
